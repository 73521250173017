<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <div class="col-12">
      <div class="card">
        <Toast />
        <h2>Concepto</h2>
        <Helper :style="{ width: '50%' }" @filter="findProdServ" :lazy="true" v-model="prodServHelper" header="Buscar Producto / Servicio SAT" 
        :headers="prodServHeaders" 
        :rows="prodServs"
        @selected="selectProdServ">
            <template v-slot:filters>
                <div class="col-6 field">
                    <label for="">ID</label>
                    <InputText v-model.trim="prodServHelper.id" />
                </div>
                <div class="col-6 field">
                    <label for="">Descripción</label>
                    <InputText v-model.trim="prodServHelper.descripcion" />
                </div>
            </template>
        </Helper>
        <DeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteEntity" /> 
        <BasicFormToolbar v-if="!view" @new="openNew" @save="save" @list="list" :actions="['new','save','list']" />
          <Fieldset legend="Datos Generales">
            <div class="p-fluid formgrid grid">
              <FormInputText v-model="entity.key_name" :valid="validate.validations.key_name" wrapperClass="field col-4" label="Clave" />
              <FormInputText v-model="entity.description" :valid="validate.validations.description" wrapperClass="field col-8" label="Descripcion" />
              <FormInputText wrapperClass="field col-4" label="Clave SAT" v-model="entity.sat_key" :search="true" @search="prodServHelper.visible = true" :tooltip="'Se obtiene del catalogo SAT c_ClaveProdServ'"  />
              <FormInputText wrapperClass="field col-8" label="Descripcion SAT" v-model="entity.sat_description" :disabled="true" />
    
              <div class="field col-4">
                <label>Unidad Medida SAT</label>
                <Dropdown
                    :filter="true"
                    @change="selectUnit" 
                    v-model="entity.sat_unit_key"
                    :virtualScrollerOptions="{ itemSize: 20 }"
                    :options="c_ClaveUnidad"
                    :valid="validate.validations.sat_unit_key"
                    optionLabel="c_ClaveUnidad"
                    optionValue="c_ClaveUnidad"
                    :filterFields="['c_ClaveUnidad', 'Nombre']"
                     >
                    <template #option="slotProps">
                        <div>
                            <div>{{ slotProps.option.c_ClaveUnidad }} - {{ slotProps.option.Nombre.toUpperCase() }}</div>
                        </div>
                    </template>
                <small class="p-invalid" v-if="validate.validations.sat_unit_key === false"> 
                  Seleccione una opcion
                </small>
              </Dropdown>
              </div>
              <FormInputText :valid="validate.validations.unit_name"  wrapperClass="field col-6" v-model="entity.unit_name" label="Unidad Medida" :tooltip="'Se obtiene del catalogo SAT c_ClaveUnidad'" />
              <FormInputNumber :valid="validate.validations.unit_value"  mode="decimal" :minFractionDigits="2" :maxFractionDigits="6" wrapperClass="field col-2" v-model="entity.unit_value" label="P.U." />
            </div>
          </Fieldset> <br>
       
        <Fieldset legend="Impuestos">
          <div class="p-fluid formgrid grid">
            <FormDropdown :valid="validate.validations.tax_object" v-model="entity.tax_object" wrapperClass='field col-4' label="Objeto de Impuestos" :options="c_ObjetoImp" optionLabel="Descripcion" optionValue="c_ObjetoImp" />
            <FormInputNumber :min="0" :max="100" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" prefix="%" :disabled="entity.tax_object == '01'" v-model="entity.iva_transferred" wrapperClass='field col-2' label="IVA Trasladado"/> 
            <FormInputNumber :min="0" :max="100" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" prefix="%" :disabled="entity.tax_object == '01'" v-model="entity.iva_detained" wrapperClass='field col-2' label="IVA Retenido"/> 
            <FormInputNumber :min="0" :max="100" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" prefix="%" :disabled="entity.tax_object == '01'" v-model="entity.ieps_transferred" wrapperClass='field col-2' label="IEPS Trasladado"/> 
            <FormInputNumber :min="0" :max="100" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" prefix="%" :disabled="entity.tax_object == '01'" v-model="entity.ieps_detained" wrapperClass='field col-2' label="IEPS Retenido"/> 
          </div>
        </Fieldset> <br>

        <BasicDatatable @edited="edit" @deleted="confirmDelete" :headers="headers" :rows="entities" :rowaction="true" :rowdelete="true" :rowedit="true" />   
          
      </div>
    </div>
  </div>
</template>

<script>
import { Concept } from '../../../models/cfdi40/Concept';
import { FilterMatchMode } from "primevue/api";
import FormInputText from '../../../components/general/FormInputText.vue'
import FormDropdown from '../../../components/general/FormDropdown.vue'
import FormInputNumber from '../../../components/general/FormInputNumber.vue'
import BasicDatatable from '../../../components/general/BasicDatatable.vue'
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue'
import DeleteDialog from '../../../components/general/DeleteDialog.vue'
import Helper from '../../../components/general/HelperDialog.vue';

import {
  HeaderGrid,
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
  satCatalogo,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import { Invoice } from '../../../models/cfdi40/Invoice';
export default {
  emits: ['save', 'update'],
  mixins: [Session],
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prodServHeaders: [new HeaderGrid('ID', 'id'), new HeaderGrid('Descripcion', 'descripcion')],
      entity: null,
      entities: [],
      claveunidad: [],
      activeTab: 0,
      //* URL para subir el importador
      uploadURL: null,
      newDialog: false,
      deleteDialog: false,
      filters: {},
      c_ClaveUnidad: [],
      prodServHelper: {
        visible: false,
        id: null,
        descripcion: null,
      },
      c_ObjetoImp: [
                { c_ObjetoImp: '01', Descripcion: 'No objeto de impuesto.' },
                { c_ObjetoImp: '02', Descripcion: 'Sí objeto de impuesto.' },
                { c_ObjetoImp: '03', Descripcion: 'Sí objeto del impuesto y no obligado al desglose.' },
            ],
      c_Impuestos: [
          { c_Impuestos: '01', Descripcion: 'ISR' },
          { c_Impuestos: '02', Descripcion: 'IVA' },
          { c_Impuestos: '03', Descripcion: 'IEPS' },
      ],
      //* Aqui agregamos las reglas de validacion, si solo agregamos el nombre sera
      //* 'required' de tipo 'text'
      rules: [
        new Rule({ name: "description" }),
        new Rule({ name: "key_name" }),
        new Rule({ name: "sat_unit_key" }),
        new Rule({ name: "unit_name" }),
        new Rule({ name: "unit_value" }),
        new Rule({ name: "tax_object" }),
      ],
      //* Aqui agregamos el estatus de la validacion, inicializar en las props 'null'
      //* y el valid general en false (por que no esta validado aun)
      validate: {
        valid: false,
        validations: {
          description: null,
          key_name: null,
          sat_unit_key: null,
          unit_name: null,
          unit_value: null,
          tax_object: null,
        },
      },
      //* Headers donde pondremos el nombre y el valor del datatable
      headers: [
        new HeaderGrid("Clave", "key_name"),
        new HeaderGrid("Descripcion", "description"),
        new HeaderGrid("SAT Unidad Medida", "sat_unit_key"),
        new HeaderGrid("Unidad Medida", "unit_name"),
        new HeaderGrid("Precio Unitario", "unit_value", { type: "decimal"}),
      ],
      conceptHelperHeaders: [
                new HeaderGrid("Clave", "key_name"),
                new HeaderGrid("Descripcion", "description"),
                new HeaderGrid("SAT Unidad Medida", "sat_unit_key"),
                new HeaderGrid("Unidad Medida", "unit_name"),
                new HeaderGrid("Precio Unitario", "unit_value", { type: "decimal"}),
            ],
            prodServs: [],
      loading: false,
    };
  },
  components: { Helper, Loader, DeleteDialog, FormInputText, FormDropdown, FormInputNumber, BasicDatatable, BasicFormToolbar},
  created() {
    console.log(this.session);
    this.entity = new Concept(this.session);
    this.uploadURL =
      this.$config.api_route +
      "comercial/Concept/Import/" +
      this.session.empresa +
      "/" +
      this.session.sucursal +
      "/" +
      this.session.usuario;
    this.initFilters();
  },
  watch: {
    ['entity.tax_object'](newValue) {
      if (newValue == "01") {
        this.entity.iva_transferred = 0.00;
        this.entity.iva_detained = 0.00;
        this.entity.ieps_transferred = 0.00;
        this.entity.ieps_detained = 0.00;
      }
    }
  },
  async mounted() {
    this.c_ClaveUnidad = await satCatalogo(16);
    await this.refresh();
  },
  methods: {
    async findProdServ() {
        try {
            this.loading = true;
            this.prodServs = await new Invoice(this.session).prodserv({
                id: this.prodServHelper.id,
                descripcion: this.prodServHelper.descripcion,
            });
        } catch (error) {
            this.$toast.add(new ErrorToast(error));
        } finally {
            this.loading = false;
        }
    },
    selectUnit() {
      console.log(this.entity.sat_unit_key);
      this.entity.unit_name = this.c_ClaveUnidad.find(x => x.c_ClaveUnidad == this.entity.sat_unit_key).Nombre.toUpperCase();
    },
    openNew() {
      this.entity = new Concept(this.session);
      if (!this.isModal) 
        this.newDialog = true;
    },
    hideDialog() {
      this.newDialog = false;
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$emit('update', entity);

          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          console.log(JSON.stringify(this.entity))
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra
          this.$emit('save', entity);
          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new Concept(this.session);
        this.newDialog = false;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    selectProdServ(prodserv) {
      this.entity.sat_key = prodserv.id;
      this.entity.sat_description = prodserv.descripcion;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new Concept(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    uploadError() {
      this.$toast.add(new ErrorToast("Error al intentar importar archivo"));
    },
    async refresh() {
      this.loading = true;
      try {
        this.entities = await new Concept(this.session).all();
        this.claveunidad = await new satCatalogo(16);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },

    async list() {
        this.loading = true;
        try {
          this.entity.branch = this.session.branch;
          this.entity.company = this.session.company;
          this.entities = await new Concept().data({
            branch: this.session.branch,
            company: this.session.company,
            key_name: this.entity.key_name,
            description: this.entity.description,
            sat_unit_key: this.entity.sat_unit_key,
            unit_name: this.entity.unit_name,
            unit_value: this.entity.unit_value
          });
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loading = false;
        }
      },
    },
  };
  
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
