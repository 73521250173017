import {
    Abstract
} from './Abstract';

export class Concept extends Abstract {
    constructor(session) {
        super('invoice/Concept', session);
        this.description = null;
        this.key_name = null;
        this.sat_key = null;
        this.sat_unit_key = null;
        this.unit_name = null;
        this.unit_value = 0.00;
        this.tax_object = "01";
        this.iva_transferred = 0.00;
        this.iva_detained = 0.00;
        this.ieps_transferred = 0.00;
        this.ieps_detained = 0.00;
    }
}